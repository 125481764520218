import {Trans} from '../../i18n/trans';
import {Button} from '../buttons/button';
import {Link} from 'react-router-dom';
import imgUrl1 from './404-1.png';
import imgUrl2 from './404-2.png';

export function NotFoundPage() {
  return (
    <div className="lg:px-96 lg:py-96 md:py-80 md:px-176 px-16 py-96 items-center flex justify-center flex-col-reverse lg:flex-row md:gap-112 gap-64">
      <header id="not_found_header">
      <div id="not_found_div">
      <img src="https://linkcrafter.com/storage/branding_media/9f10e2ab-d2c6-496b-b6e7-9d5d29057a7a.svg"></img>
      </div>

      </header>
      <div className="xl:pt-96 w-full xl:w-1/2 relative pb-48 lg:pb-0">
        <div className="relative">
          <div className="absolute">
            <div className="relative z-10">
              <h1 className="my-8 text-main font-bold text-2xl" id='page_title_broken_page'>
                <Trans message="Aradığın sayfa bulunamadı" />
              </h1>
              
              <Button
                className="my-8"
                elementType={Link}
                size="lg"
                to="https://linkcrafter.com/"
                variant="flat"
                color="primary"
                id='page_button_broken_page'
              >
                <Trans message="Ana sayfaya dön" />
              </Button>
            </div>
          </div>
          {/* <div className="dark:opacity-5">
            <img src={imgUrl2 as any} alt="" />
          </div> */}
        </div>
      </div>
      <div className="dark:opacity-80">
        <img src={imgUrl1 as any} alt="" />
      </div>
    </div>
  );
}
