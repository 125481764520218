import clsx from 'clsx';
import {LandingPageContent} from './landing-page-content';
import {Navbar} from '@common/ui/navigation/navbar/navbar';
import {Button, ButtonProps} from '@common/ui/buttons/button';
import {MixedImage} from '@common/ui/images/mixed-image';
import {Footer} from '@common/ui/footer/footer';
import {Trans} from '@common/i18n/trans';
import {AdHost} from '@common/admin/ads/ad-host';
import {Link} from 'react-router-dom';
import {createSvgIconFromTree} from '@common/icons/create-svg-icon';
import {MenuItemConfig} from '@common/core/settings/settings';
import {Fragment, useState} from 'react';
import {DefaultMetaTags} from '@common/seo/default-meta-tags';
import {useSettings} from '@common/core/settings/use-settings';
import {LandingPageNewLinkForm} from '@app/landing/landing-page-new-link-form';
import {useAuth} from '@common/auth/use-auth';
import {LandingPageStats} from '@app/landing/landing-page-stats';
import {PricingTable} from '@common/billing/pricing-table/pricing-table';
import {BillingCycleRadio} from '@common/billing/pricing-table/billing-cycle-radio';
import {UpsellBillingCycle} from '@common/billing/pricing-table/find-best-price';
import {useProducts} from '@common/billing/pricing-table/use-products';
import React from 'react';
import ReactDOM from 'react-dom';

function handleClick() {
  console.log("Button clicked");
  location.href="/login";
}

class Accordion extends React.Component <any, any> {
  constructor(props : any) {
  super(props);
  this.state = {
    display: 'none',
    transform: ''
  };
  
  this.toggle = this.toggle.bind(this);
}

toggle() {
  const newDisplay = this.state.display == 'block' ? 'none' : 'block';
  const newTransform = this.state.transform == 'rotate(180deg)' ? '' : 'rotate(180deg)';
  this.setState({
    display: newDisplay,
    transform: newTransform
  });
}

render() {
  return(
    <div className="accordion">
      <div className="accordionHeader" onClick={this.toggle}>
        <p>{this.props.accordionTitle}</p>
        <svg style={{ transform: this.state.transform }} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path d="M7.41 7.84L12 12.42l4.59-4.58L18 9.25l-6 6-6-6z"/>
          <path d="M0-.75h24v24H0z" fill="none"/>
        </svg>
      </div>
      <div className="accordionContent" style={{ display: this.state.display }}>
        <p>{this.props.accordionContent}</p>
      </div>
    </div>
  );
}
}

interface ContentProps {
  content: LandingPageContent;
}
export function LandingPage() {
  const settings = useSettings();
  const homepage = settings.homepage as {appearance: LandingPageContent};

  const showPricing =
    settings.links.homepage_pricing && settings.billing.enable;

  return (
    <Fragment>
      <DefaultMetaTags />
      <Fragment>
        <HeroHeader content={homepage.appearance} />
        <AdHost slot="landing" className="mx-14 -mt-30 mb-14 md:mb-70" />
        <PrimaryFeatures content={homepage.appearance} />
        <div className="mt-100 h-1 bg-divider" />
        <SecondaryFeatures content={homepage.appearance} />
        {settings.links?.homepage_stats && <LandingPageStats />}
        <BottomCta content={homepage.appearance} />
        {showPricing && <PricingSection content={homepage.appearance} />}
        <Footer className="landing-container" />
      </Fragment>
    </Fragment>
  );
}

function HeroHeader({content}: ContentProps) {
  const {hasPermission} = useAuth();
  const {
    links: {homepage_creation},
  } = useSettings();

  const {
    headerTitle,
    headerSubtitle,
    headerImage,
    headerImageOpacity,
    actions,
    headerOverlayColor1,
    headerOverlayColor2,
  } = content;
  let overlayBackground = undefined;

  if (headerOverlayColor1 && headerOverlayColor2) {
    overlayBackground = `linear-gradient(45deg, ${headerOverlayColor1} 0%, ${headerOverlayColor2} 100%)`;
  } else if (headerOverlayColor1) {
    overlayBackground = headerOverlayColor1;
  } else if (headerOverlayColor2) {
    overlayBackground = headerOverlayColor2;
  }

  return (
    <header className="relative isolate mb-14 overflow-hidden md:mb-60">
      <img
        data-testid="headerImage"
        src={headerImage}
        style={{
          opacity: headerImageOpacity,
        }}
        alt=""
        width="2347"
        height="1244"
        decoding="async"
        loading="lazy"
        className="absolute left-1/2 top-1/2 z-20 max-w-none -translate-x-1/2 -translate-y-1/2"
      />
      <div
        className="absolute z-10 h-full w-full bg-[rgb(37,99,235)]"
        style={{background: overlayBackground}}
      />
      <div className="relative z-30 flex h-full flex-col">
        <Navbar
          color="transparent"
          className="flex-shrink-0"
          menuPosition="homepage-navbar"
          primaryButtonColor="paper"
        />
        <div className="mx-auto flex max-w-850 flex-auto flex-col items-center justify-center px-14 py-50 text-center text-white lg:py-90">
        <img src="https://linkcrafter.com/storage/homepage/9b6842b7-71e8-4b83-9960-a24a4506f542.png" id="header_img"></img>
          {headerTitle && (
            <h1
              className="text-3xl font-normal md:text-5xl"
              data-testid="headerTitle"
            >
              <Trans message={headerTitle} />
            </h1>
          )}
          
          {headerSubtitle && (
            <div
              className="max-auto mt-24 max-w-640 text-lg tracking-tight md:text-xl"
              data-testid="headerSubtitle"
            >
              <Trans message={headerSubtitle} />
            </div>
          )}
         
          {homepage_creation && hasPermission('links.create') && (
            <LandingPageNewLinkForm content={content} />
          )}
          <div className="flex min-h-50 gap-20 pb-30 pt-70 empty:min-h-0 md:pb-50 md:pt-90">
            <CtaButton
              item={actions.cta1}
              variant="raised"
              color="primary"
              size="lg"
              radius="rounded-full"
              data-testid="cta1"
              className="min-w-180"
            />
            <CtaButton
              item={actions.cta2}
              variant="text"
              color="paper"
              size="lg"
              radius="rounded-full"
              data-testid="cta2"
            />
          </div>
          <div id='header_button_div'>
          <button id="header_button" onClick={handleClick}>Ücretsiz link oluştur</button>
          </div>
        </div>
      </div>
      <div className="absolute bottom-0 z-20 h-[6vw] w-full translate-y-1/2 -skew-y-3 transform bg"></div>
    </header>
  );
}

interface CtaButtonProps extends ButtonProps {
  item?: MenuItemConfig;
}
function CtaButton({item, ...buttonProps}: CtaButtonProps) {
  if (!item?.label || !item?.action) return null;
  const Icon = item.icon ? createSvgIconFromTree(item.icon) : undefined;
  return (
    <Button
      elementType={item.type === 'route' ? Link : 'a'}
      href={item.action}
      to={item.action}
      startIcon={Icon ? <Icon /> : undefined}
      {...buttonProps}
    >
      <Trans message={item.label} />
    </Button>
  );
}

function PrimaryFeatures({content}: ContentProps) {
  return (
    <div
      className="landing-container z-20 items-stretch gap-26 md:flex"
      id="primary-features"
    >
      {content.primaryFeatures.map((feature, index) => (
        <div
          key={index}
          className="mb-14 flex-1 rounded-2xl px-24 py-36 text-center shadow-[0_10px_30px_rgba(0,0,0,0.08)] dark:bg-alt md:mb-0"
          data-testid={`primary-root-${index}`}
        >
          <MixedImage
            className="mx-auto mb-30 h-128"
            data-testid={`primary-image-${index}`}
            src={feature.image}
          />
          <h2
            className="my-16 text-lg font-medium"
            data-testid={`primary-title-${index}`}
          >
            <Trans message={feature.title} />
          </h2>
          <div
            className="text-md text-[0.938rem]"
            data-testid={`primary-subtitle-${index}`}
          >
            <Trans message={feature.subtitle} />
          </div>
        </div>
      ))}
    </div>
  );
}

function SecondaryFeatures({content}: ContentProps) {
  return (
    <div className="relative overflow-hidden pt-100">
      <div className="landing-container relative" id="features">
        {content.secondaryFeatures.map((feature, index) => {
          const isEven = index % 2 === 0;
          return (
            <div
              key={index}
              data-testid={`secondary-root-${index}`}
              className={clsx(
                'relative z-20 mb-14 py-16 md:mb-80 md:flex',
                isEven && 'flex-row-reverse'
              )}
            >
              <img
                src={feature.image}
                className="mr-auto w-580 max-w-full rounded-lg shadow-[0_10px_30px_rgba(0,0,0,0.08)]"
                data-testid={`secondary-image-${index}`}
                alt=""
              />
              <div className="ml-30 mr-auto max-w-350 pt-30">

              <div id='secondary2_button_div'>
                <button id='secondary_header_button_div'><svg xmlns="http://www.w3.org/2000/svg" width="12" height="13" viewBox="0 0 12 13" fill="none"> <g clip-path="url(#clip0_75_680)"> <path d="M11.383 2.94973C11.2003 2.87363 10.9991 2.85362 10.805 2.89227C10.6108 2.93091 10.4326 3.02646 10.293 3.16673L9 4.45973L6.707 2.16673C6.51947 1.97926 6.26516 1.87395 6 1.87395C5.73484 1.87395 5.48053 1.97926 5.293 2.16673L3 4.45973L1.707 3.16673C1.56715 3.02692 1.38898 2.93172 1.19503 2.89315C1.00108 2.85458 0.800045 2.87439 0.617345 2.95006C0.434646 3.02573 0.278483 3.15387 0.168598 3.31828C0.0587141 3.48268 4.22243e-05 3.67598 0 3.87373L0 9.16673C0.000793929 9.82953 0.264441 10.465 0.73311 10.9336C1.20178 11.4023 1.8372 11.6659 2.5 11.6667H9.5C10.1628 11.6659 10.7982 11.4023 11.2669 10.9336C11.7356 10.465 11.9992 9.82953 12 9.16673V3.87373C12 3.67597 11.9415 3.48263 11.8316 3.31816C11.7218 3.15369 11.5657 3.02548 11.383 2.94973ZM11 9.16673C11 9.56456 10.842 9.94609 10.5607 10.2274C10.2794 10.5087 9.89782 10.6667 9.5 10.6667H2.5C2.10218 10.6667 1.72064 10.5087 1.43934 10.2274C1.15804 9.94609 1 9.56456 1 9.16673V3.87373L2.6465 5.52023C2.74026 5.61397 2.86742 5.66663 3 5.66663C3.13258 5.66663 3.25974 5.61397 3.3535 5.52023L6 2.87373L8.6465 5.52023C8.74026 5.61397 8.86742 5.66663 9 5.66663C9.13258 5.66663 9.25974 5.61397 9.3535 5.52023L11 3.87373V9.16673Z" fill="#254F1A"></path> </g> <defs><clipPath id="clip0_75_680"> <rect width="12" height="12" fill="white" transform="translate(0 0.666748)"></rect>  </clipPath> </defs></svg>Yükselt</button>
              </div>

                <small
                  className="mb-16 text-xs font-medium uppercase tracking-widest text-muted"
                  data-testid={`secondary-subtitle-${index}`}
                >
                  <Trans message={feature.subtitle} />
                </small>
                <h3
                  className="py-16 text-3xl"
                  data-testid={`secondary-title-${index}`}
                >
                  <Trans message={feature.title} />
                </h3>
                <div className="h-2 w-50 bg-black/90 dark:bg-divider" />
                <div
                  className="my-20 text-[0.938rem]"
                  data-testid={`secondary-description-${index}`}
                >
                  <Trans message={feature.description} />
                </div>
                <div id='secondary_button_div'>
                  <button id='try_button' onClick={handleClick}>Ücretsiz dene</button>
                </div>
              </div>
            </div>
          );
        })}
      </div>

      <div className="landing-container relative overflow-hidden" id='sss_area'>
        <h2 id='sss_title'>Sık sorulan sorular</h2>
        
        <div className='accordions'>
      <Accordion
         accordionTitle="Neden biyografide bağlantı araçlarını kullanmalıyım?"
         accordionContent="Biyografide bağlantı araçları, sosyal medya varlığınızı güçlendirmek ve takipçilerinizle daha etkili bir şekilde etkileşim kurmak için harika bir araçtır. Bu araçlar sayesinde tek bir bağlantıyla birden fazla içeriğe yönlendirme yapabilir, marka bilinirliğinizi artırabilir ve hedeflerinize daha kolay ulaşabilirsiniz."
       />
      <Accordion
         accordionTitle="Instagram biyografideki bağlantı ne anlama geliyor?"
         accordionContent="Instagram ve TikTok gibi sosyal medya platformlarında biyografideki bağlantı, profilinizin en üst kısmında yer alan ve tek bir tıklamayla farklı bir web sitesine veya sayfaya yönlendiren bir linktir. Bu link genellikle profilinizde daha fazla bilgi veya içeriğe ulaşmak isteyen takipçilerinizi farklı platformlara yönlendirmek için kullanılır."
       />
      <Accordion
         accordionTitle="Linkcrafter’ı ücretsiz bir şekilde kullanabilir miyim?"
         accordionContent="Evet, Linkcrafter'ı tamamen ücretisiz bir platormdur. Linkcrafter'ı ücretsiz kullanmak için yapmanız gerekenler: Linkcrafter'ın resmi web sitesini ziyaret edin. Ücretsiz hesap oluşturun. Uzun linkinizi girin ve kısaltma işlemini başlatın."
       />
    </div>

        
      </div>

    </div>
  );
}

interface PricingSectionProps {
  content: LandingPageContent;
}
function PricingSection({content}: PricingSectionProps) {
  const query = useProducts('landingPage');
  const [selectedCycle, setSelectedCycle] =
    useState<UpsellBillingCycle>('yearly');
  return (
    <div className="py-80 sm:py-128" id="pricing">
      <div className="mx-auto max-w-1280 px-24 lg:px-32">
        <div className="md:text-center">
          <h2
            className="font-display text-3xl tracking-tight sm:text-4xl"
            data-testid="pricingTitle"
          >
            <Trans message={content.pricingTitle} />
          </h2>
          <p className="mt-16 text-lg text-muted" data-testid="pricingSubtitle">
            <Trans message={content.pricingSubtitle} />
          </p>
        </div>
        <BillingCycleRadio
          products={query.data?.products}
          selectedCycle={selectedCycle}
          onChange={setSelectedCycle}
          className="my-50 flex justify-center"
          size="lg"
        />
        <PricingTable
          selectedCycle={selectedCycle}
          productLoader="landingPage"
        />
      </div>
    </div>
  );
}

function BottomCta({content}: ContentProps) {
  return (
    <div>
      
    </div>
  );
}
